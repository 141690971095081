import { AppBar, Stack, Toolbar } from "@mui/material";
import { Container } from "@mui/system";
import { styled,useTheme } from '@mui/material/styles'; 
import { useWeb3React } from '@web3-react/core';
import  { useEffect} from 'react'; 
import { useAtom } from 'jotai'; 
import {
  useConnectWalletDialog,
  useSignMessageDialog,
  useSignMessageDialognew,
  useTransactions,
} from '../../hooks/app';
import {
  drawerIsOpenAtom,
  showSelectCurrencyAtom,
  showSelectLocaleAtom,
  switchNetworkChainIdAtom,
  switchNetworkOpenAtom,
} from '../../state/atoms';
import SignMessageDialognew from '../../components/dialogs/SignMessageDialognew';
import SignMessageDialog from '../../components/dialogs/SignMessageDialog';
import { SwitchNetworkDialog } from '../../components/dialogs/SwitchNetworkDialog';
import TransactionDialog from '../../components/dialogs/TransactionDialog';  

import ConnectWalletDialog from '../../components/dialogs/ConnectWalletDialog';
import SelectCurrencyDialog from '../../components/dialogs/SelectCurrencyDialog';
import SelectLanguageDialog from '../../components/dialogs/SelectLanguageDialog';

import Logo from "./../../components/Logo";
import useOffSetTop from './../../hooks/useOffSetTop';
import cssStyles from './../../utils/cssStyles';
import MainMenu from "./MainMenu";



const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    height: 60,
    transition: theme.transitions.create(['height', 'background-color'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
}));

export default function Header() {
    const isOffset = useOffSetTop(60);
    const theme = useTheme();
    const { connector, provider } = useWeb3React();  
    const transactions = useTransactions();
   // const navigate = useNavigation();
    const [switchOpen, setSwitchOpen] = useAtom(switchNetworkOpenAtom);
    const [switchChainId, setSwitchChainId] = useAtom(switchNetworkChainIdAtom);
  
    const [showSelectCurrency, setShowShowSelectCurrency] = useAtom(
      showSelectCurrencyAtom
    );
  
    const [showSelectLocale, setShowShowSelectLocale] =
      useAtom(showSelectLocaleAtom);
  
    const connectWalletDialog = useConnectWalletDialog();
  
    const handleCloseConnectWalletDialog = () => {
      connectWalletDialog.setOpen(false);
    };
  
    const handleCloseTransactionDialog = () => {
      if (transactions.redirectUrl) {
       // navigate(transactions.redirectUrl, { replace: true });   
       // navigate.replace(transactions.redirectUrl);
      }
      transactions.setRedirectUrl(undefined);
      transactions.setDialogIsOpen(false);
      transactions.setHash(undefined);
      transactions.setType(undefined);
      transactions.setMetadata(undefined);
      transactions.setError(undefined);
    };
  
    const handleCloseSwitchNetworkDialog = () => {
      setSwitchChainId(undefined);
      setSwitchOpen(false);
    };
  
    const signMessageDialog = useSignMessageDialog();
  
    const handleCloseSignMessageDialog = () => {
      signMessageDialog.setOpen(false);
      signMessageDialog.setError(undefined);
      signMessageDialog.setIsSuccess(false);
      signMessageDialog.setMessage(undefined);
    };

    const signMessageDialognew = useSignMessageDialognew();
  
    const handleCloseSignMessageDialognew = () => {
      signMessageDialognew.setOpen(false);
      signMessageDialognew.setError(undefined);
      signMessageDialognew.setIsSuccess(false);
      signMessageDialognew.setMessage(undefined);
      signMessageDialognew.setActivestep(undefined);
      signMessageDialognew.setEndinfo(undefined);
      signMessageDialognew.setSteps(undefined);
      signMessageDialognew.setTitle(undefined); 
    };
  
    const handleCloseCurrencySelect = () => {
      setShowShowSelectCurrency(false);
    };
  
    const handleCloseLocaleSelect = () => {
      setShowShowSelectLocale(false);
    }; 

    useEffect(() => {
    try {
      if (connector?.connectEagerly) {
        connector.connectEagerly();
       // handlemenuClose();
      }
    } catch(error) {
     // handlemenuClose();
     // console.log(error);
    }
  }, []); // eslint-disable-line  
 
    return (
        <AppBar sx={{ bgcolor: 'transparent', boxShadow: '1', color:'black' }}>
            <ToolbarStyle
                disableGutters
                sx={{
                    ...(isOffset && {
                        ...cssStyles(theme).bgBlur(),
                        height: { md: 60 },
                    }),
                }}
            >
                <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Logo />
                    <TransactionDialog
                        dialogProps={{
                        open: transactions.isOpen,
                        onClose: handleCloseTransactionDialog,
                        fullWidth: true,
                        maxWidth: 'xs',
                        }}
                        hash={transactions.hash}
                        metadata={transactions.metadata}
                        type={transactions.type}
                        error={transactions.error}
                    />
                    <SignMessageDialog
                        dialogProps={{
                        open: signMessageDialog.open,
                        onClose: handleCloseSignMessageDialog,
                        fullWidth: true,
                        maxWidth: 'xs',
                        }}
                        error={signMessageDialog.error}
                        success={signMessageDialog.isSuccess}
                        message={signMessageDialog.message}
                    />
                     <SignMessageDialognew
                        dialogProps={{
                        open: signMessageDialognew.open,
                        onClose: handleCloseSignMessageDialognew,
                        fullWidth: true,
                        maxWidth: 'sm',
                        }}
                        error={signMessageDialognew.error}
                        success={signMessageDialognew.isSuccess}
                        title={signMessageDialognew.title}
                        message={signMessageDialognew.message}
                        steps={signMessageDialognew.steps}
                        activestep={signMessageDialognew.activestep}
                        endinfo={signMessageDialognew.endinfo}
                    />
                    <SwitchNetworkDialog
                        dialogProps={{
                        open: switchOpen,
                        onClose: handleCloseSwitchNetworkDialog,
                        fullWidth: true,
                        maxWidth: 'xs',
                        }}
                        chainId={switchChainId}
                    />  
                    <ConnectWalletDialog
                        dialogProps={{
                        open: connectWalletDialog.isOpen,
                        onClose: handleCloseConnectWalletDialog,
                        fullWidth: true,
                        maxWidth: 'sm',
                        }}
                      />  
                    <Stack direction="row" marginLeft={'auto'}>
                        <MainMenu />
                    </Stack>
                </Container>
            </ToolbarStyle>
        </AppBar>
    )
}
