import { MetaMask } from '@web3-react/metamask';
import { WalletConnect } from '@web3-react/walletconnect';
import { BigNumber, ethers, utils } from "ethers"
import { Connector } from '@web3-react/types'; 
import { CoinbaseWallet } from '@web3-react/coinbase-wallet';  
import { ChainId } from '../constants/enum'; 
import { NETWORKS } from '../constants/chain'; 
import { getAddChainParameters,mcontractAddresses } from '../constants/chains';
import moment from 'moment';
///import metaMaskImage from '../assets/images/metamask-fox.svg';
// import walletConnectImage from '../assets/images/walletconnect-circle-blue.svg'; 
export function gweiToWei(value: number | bigint): bigint {
  return BigInt(utils.parseUnits(value.toString(), "gwei").toString())
}

export const estimateGasLimit_bak_old= async (cb: () => any) =>{
    let gas = 350000
    let gasPrice =36000000000
    try {
      gas = (await cb()).toNumber()
      console.log('estimateGas gas from source:',gas)
    } catch (e:any) {
      let matched =
        e.message.match(/\(supplied gas (.*)\)/) ||
        e.message.match(/\(gas required exceeds allowance (.*)\)/)
      if (matched) {
        gas = parseInt(matched[1])
      }
      console.log({ gas, e, matched })
    }
  // if (gas > 0) {
  //   gas= gas + 2000
  // }  
      console.log('gas from estimatedGa...:',gas) 
    // Get standard max priority fee in matic network
    const { standard } = await fetch(
      'https://gasstation.polygon.technology/v1',
    ).then((res) => res.json());

    if(standard) gasPrice = Number(parseInt(standard) * 1000000000);
    const gasLimit = Number(gas*2);
    console.log('gasLimit, gasPrice, standard:',{ gasLimit, gasPrice, standard})
    return { 
      gasPrice,
      gasLimit
    }; 
  }
  export const estimateGasLimit= async (cb: () => any) =>{
    let gas = 350000
    let endgas = 350000
    let gasPrice =36000000000
    try {
      gas = (await cb()).toNumber()
      console.log('estimateGas gas from source:',gas)
    } catch (e:any) {
      let matched =
        e.message.match(/\(supplied gas (.*)\)/) ||
        e.message.match(/\(gas required exceeds allowance (.*)\)/)
      if (matched) {
        gas = parseInt(matched[1])
      }
      console.log({ gas, e, matched })
    }
   if (gas > endgas) {
      endgas = gas  
    }else{
      endgas = endgas + 2000
    }  
      console.log('gas from estimatedGa...:',gas) 
    // Get standard max priority fee in matic network
    const { fast } = await fetch(
      'https://gasstation.polygon.technology/v2',
    ).then((res) => res.json());

    if(fast?.maxFee) gasPrice = Number(parseInt(fast?.maxFee) * 1000000000);
    const gasLimit = Number(endgas*2);
    console.log('gasLimit, gasPrice, fast:',{ gasLimit, gasPrice, fast})
    return { 
      gasPrice,
      gasLimit
    }; 
  }
export const  getFeeData = async(chainId:number): Promise<any> =>{
  let gasStationURL = "https://gasstation.polygon.technology/v2" ;
  //if(chainId==137)gasStationURL = "https://gasstation.polygon.technology/v2";

  const data = await fetch(gasStationURL);
  const json = await data.json(); 
  const fallback = "400000000000"; // 40 gwei 
  const maxFeePerGas = json?.fast?.maxFee || fallback;
  const maxPriorityFeePerGas = json?.fast?.maxPriorityFee   || fallback;
  const gasPrice =   fallback;

  return {
      maxFeePerGas: ethers.utils.parseUnits(Math.ceil(maxFeePerGas).toString(), 'gwei').toString(),
      maxPriorityFeePerGas: ethers.utils.parseUnits(Math.ceil(maxPriorityFeePerGas).toString(), 'gwei').toString(),  
      gasPrice: ethers.utils.parseUnits('40', 'gwei').toString(),  
  };
}

export const getChainIdFromName = (chainName: string) => {
  const keys = Object.keys(NETWORKS).map(Number);

  let key = keys.find((key) => NETWORKS[key].slug === chainName);

  if (key !== undefined) {
    return NETWORKS[key];
  }

  return undefined;
};

export const getNetworkprcnameFromChainId = (chainId?: ChainId) => {
  if (chainId) {
    return NETWORKS[chainId]?.rpcname||'polygon-mainnet';
  }else{
    return 'polygon-mainnet';
  }
};

export const getcolletionsFromChainId = (chainId?: ChainId) => {
  if (chainId) {
    return mcontractAddresses[chainId]?.colletions||[];
  }
};

export const getNetworksurrportnetFromChainId = (chainId?: ChainId) => {
  if (chainId) {
    return NETWORKS[chainId]?.surrportnet || false;
  }
};

export const getNetworkSlugFromChainId = (chainId?: ChainId) => {
  // console.log("getNetworkSlugFromChainId ok !",chainId);
  if (chainId) {
    return NETWORKS[chainId]?.slug || 'polygon';
  }else{
    return 'polygon';
  }
};
export function stringToAddress(string?: string): `0x${string}` | undefined {
  if (!string) {
    return undefined;
  }
  return ethers.utils.getAddress(string) as `0x${string}`;
}

export const getAccessControlConditions721 = (tokenId: number, chainId: number,contractAddress?:string) => {
   const accessControlConditions: any[] = [
    {
      contractAddress: stringToAddress(contractAddress),
      standardContractType: 'ERC721',
      chain: getNetworkSlugFromChainId(chainId) , 
      method: 'ownerOf',
      parameters: [`${tokenId}`],
      returnValueTest: { 
        comparator: '=',
        value: ':userAddress',
      },
    },
  ];
  return accessControlConditions;
}

export const generateAccessControlConditions1155 = (tokenId: number, chainId: number,contractAddress:string) => {
  const accessControlConditions: any[] =  [
      {
      contractAddress: stringToAddress(contractAddress),
      standardContractType: 'ERC1155',
      chain: getNetworkSlugFromChainId(chainId),
      method: 'balanceOf',
      parameters: [':userAddress', `${tokenId}`],
      returnValueTest: { 
        comparator: '>',
        value: '0'
      },
    },
  ];
  return accessControlConditions;
}

export const getProviderByChainId = (chainId?: ChainId) => {
  if (chainId) {
    if (NETWORKS[chainId].providerRpcUrl) {
      return new ethers.providers.JsonRpcProvider(
        NETWORKS[chainId].providerRpcUrl
      );
    }
  }
};

export const truncateAddress = (address: string | undefined) => {
  if (address !== undefined && ethers.utils.isAddress(address)) {
    return `${address.slice(0, 7)}...${address.slice(address.length - 5)}`;
  }
  return '';
};

export function getName(connector: any) {
  if (connector instanceof MetaMask) return 'MetaMask';
  if (connector instanceof WalletConnect) return 'WalletConnect';
  return 'Unknown';
}

export function getWalletIcon(connector: any) {
  if (connector instanceof MetaMask) return '../assets/images/metamask-fox.svg';
  if (connector instanceof WalletConnect) return '../assets/images/walletconnect-circle-blue.svg';
  return undefined;
}

export function checkForValidToken(activeuser?:string,account?:string,authjwk?:string,auths?:any) {
  if(activeuser&&account&&authjwk&&auths){   
      
    let newauth: any[]=[];
     if(auths&&auths.length>0) newauth = JSON.parse(auths);

     const tokenIndex = newauth?.findIndex((t:any) =>
       isAddressEqual(t?.address, account)
     ); 
     if (tokenIndex > -1) { 
         //onChangeactiveuser(useraddress,newauth[tokenIndex].token);  
         if(newauth[tokenIndex].expiry){
           if (moment().isSameOrAfter(moment(newauth[tokenIndex].expiry))){
            return false;; 
           }else{
            return true;; 
           }

         }else{
          return false;
         }
     }else{  
      return false;
     }  
 }else{
  return false;
 } 

}

export function isAddressEqual(address?: string, other?: string) {
  if (address === undefined || other === undefined) {
    return false;
  }

  if (!ethers.utils.isAddress(address) || !ethers.utils.isAddress(other)) {
    return false;
  }

  return address.toLowerCase() === other.toLowerCase();
}

export function getBlockExplorerUrl(chainId?: number) {
  if (chainId) {
    return NETWORKS[chainId].explorerUrl;
  }
}

export function getNativeCurrencySymbol(chainId?: number) {
  if (chainId) {
    return NETWORKS[chainId]?.nativeCurrency?.symbol || NETWORKS[chainId]?.symbol;
  }
}

export function getChainName(chainId?: number) {
  if (chainId) {
    return NETWORKS[chainId]?.name;
  }
}

export function getNativeCurrencyName(chainId?: number) {
  if (chainId) {
    return NETWORKS[chainId]?.nativeCurrency?.name || NETWORKS[chainId]?.name;
  }
}

export function getChainLogoImage(chainId?: number) {
  if (chainId) {
    return NETWORKS[chainId]?.imageUrl;
  }
}

export function getNativeCurrencyImage(chainId?: number) {
  if (chainId) {
    return NETWORKS[chainId]?.nativeCurrencyUrl || NETWORKS[chainId]?.imageUrl;
  }
}

 

export async function switchNetwork(connector:Connector,chainId: number) { 
  console.log('switchNetwork chainId:',chainId) 
  console.log('switchNetwork connector:',connector) 
 // const mycn = connectors['metaMask']; 
    try {
      if  (chainId === -1 || chainId == undefined)
      {
       // setError(undefined)
        return
      }

      if (
        connector instanceof MetaMask ||
        connector instanceof WalletConnect ||
        connector instanceof CoinbaseWallet
      ) {
        console.log('starting switchNetwork connector:',connector) 
        return await connector.activate(chainId)
      } else {
        return await connector.activate(getAddChainParameters(chainId))
      } 

    } catch (error) {
      return error
    }
 
}
